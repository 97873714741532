import React from 'react'
import { Link } from 'react-router-dom'

function SubscriptionTime() {
  return (
    <div className='w-full text-center'>
      <h1 className='text-gray-700 text-2xl'>Access denied</h1>
      <h1 className='text-gray-800 text-4xl my-4'>Upgrade to gold or platinum to use this feature</h1>
      <Link to='/checkout' className='text-md bg-blue-700 text-white px-8 py-2 rounded-full'>Purchase it Now</Link>
    </div>
  )
}

export default SubscriptionTime
