import React from 'react';
import ProductionForm from './Form';

const Production = () => {
    return (
        <>
            <div className='mb-8'>
                <h1 className='text-5xl font-light mb-2'>Push your Invoice to Production</h1>
                <p className='text-md text-gray-600'>Generate the production certificate after pushing your invoice to production.</p>
            </div>
            <ProductionForm></ProductionForm>
        </>
    );
};

export default Production;