import React, { useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../context/data';

function Checkout() {
    const { user } = useUser();
    const { authURL } = useAuth();
    const [selectedPackage, setSelectedPackage] = useState(null);

    const handlePackageSelect = (packageName, amount, duration) => {
        setSelectedPackage({ packageName, amount, duration });
        console.log(`Package: ${packageName}, Amount: ${amount}, Duration: ${duration}`);
    };

    const handleProceedToPayment = async () => {
        try {
            const purchasedOn = new Date();

            // Set expiry based on package type
            let purchasedExpiry = new Date(purchasedOn);
            if (selectedPackage?.duration === 'Monthly') {
                purchasedExpiry.setDate(purchasedExpiry.getDate() + 30); // Add 30 days
            } else if (selectedPackage?.duration === 'Yearly') {
                purchasedExpiry.setFullYear(purchasedExpiry.getFullYear() + 1); // Add 1 year
            } else if (selectedPackage?.duration === 'Minute') {
                purchasedExpiry.setMinutes(purchasedExpiry.getMinutes() + 1); // Add 1 minute
            }

            const purchasedOnFormatted = purchasedOn.toISOString();
            const purchasedExpiryFormatted = purchasedExpiry.toISOString();

            const response = await fetch(`${authURL}/update-user-data`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: user.primaryEmailAddress.emailAddress,
                    status: 'premium',
                    package: selectedPackage?.packageName,
                    purchasedOn: purchasedOnFormatted,
                    purchasedExpiry: purchasedExpiryFormatted
                })
            });

            if (response.ok) {
                alert("Status updated to premium successfully!");
                window.location.href = '/dashboard';
            } else {
                alert("Failed to update status. Please try again.");
            }
        } catch (error) {
            console.error("Error updating user status:", error);
            alert("There was an error updating the status.");
        }
    };

    const subtotal = selectedPackage?.amount || 0;
    const total = subtotal > 0 ? subtotal : 0;

    return (
        <>
            <h1></h1>
            <div className='max-w-7xl mx-auto my-44 flex items-start justify-start space-x-4'>
                <div className='h-[32rem] bg-slate-100 border border-slate-200 rounded-lg w-full p-8 flex flex-col items-start justify-start space-y-2'>
                    <h1 className='text-4xl text-gray-800 font-semibold mb-4'>Checkout Form</h1>
                    <div className='flex items-center justify-center space-x-4 w-full'>
                        <div className="relative mb-4 w-full">
                            <label htmlFor="name" className="leading-7 text-sm text-gray-600">Full Name</label>
                            <input type="text" value={user.fullName} className="w-full bg-white rounded-lg border border-slate-300 focus:border-slate-800 focus:ring-2 focus:ring-slate-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" readOnly />
                        </div>
                        <div className="relative mb-4 w-full">
                            <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                            <input type="email" value={user.primaryEmailAddress.emailAddress} className="w-full bg-white rounded-lg border border-slate-300 focus:border-slate-800 focus:ring-2 focus:ring-slate-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" readOnly />
                        </div>
                    </div>
                    <div className='flex items-center justify-center space-x-4 w-full'>
                        <div className="relative mb-4 w-full">
                            <label htmlFor="name" className="leading-7 text-sm text-gray-600">Phone</label>
                            <input type="text" className="w-full bg-white rounded-lg border border-slate-300 focus:border-slate-800 focus:ring-2 focus:ring-slate-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" readOnly />
                        </div>
                        <div className="relative mb-4 w-full">
                            <label htmlFor="email" className="leading-7 text-sm text-gray-600">Country</label>
                            <input type="email" className="w-full bg-white rounded-lg border border-slate-300 focus:border-slate-800 focus:ring-2 focus:ring-slate-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" readOnly />
                        </div>
                    </div>
                    <div className='flex items-center justify-center space-x-4 w-full'>
                        <div className="relative mb-4 w-full">
                            <label htmlFor="name" className="leading-7 text-sm text-gray-600">Address</label>
                            <input type="text" className="w-full bg-white rounded-lg border border-slate-300 focus:border-slate-800 focus:ring-2 focus:ring-slate-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" readOnly />
                        </div>
                        <div className="relative mb-4 w-full">
                            <label htmlFor="email" className="leading-7 text-sm text-gray-600">Card Number</label>
                            <input type="email" className="w-full bg-white rounded-lg border border-slate-300 focus:border-slate-800 focus:ring-2 focus:ring-slate-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" readOnly />
                        </div>
                    </div>
                    <div className='flex items-center justify-center space-x-4 w-full'>
                        <div className="relative mb-4 w-full">
                            <label htmlFor="name" className="leading-7 text-sm text-gray-600">Expiry</label>
                            <input type="text" className="w-full bg-white rounded-lg border border-slate-300 focus:border-slate-800 focus:ring-2 focus:ring-slate-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" readOnly />
                        </div>
                        <div className="relative mb-4 w-full">
                            <label htmlFor="email" className="leading-7 text-sm text-gray-600">CVV</label>
                            <input type="email" className="w-full bg-white rounded-lg border border-slate-300 focus:border-slate-800 focus:ring-2 focus:ring-slate-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" readOnly />
                        </div>
                    </div>
                </div>

                <div className='h-[32rem] bg-slate-100 border border-slate-200 rounded-lg w-1/2 p-6 flex flex-col items-start justify-start space-y-2'>
                    <h1 className='text-2xl text-gray-800 font-semibold mb-1'>Package Details</h1>

                    {/* New 1-minute subscription option */}
                    {/* <div className="bg-white border border-slate-200 text-gray-800 p-4 rounded-md w-full">
                        <div className="flex items-center space-x-2">
                            <input
                                type="radio"
                                name="package"
                                onChange={() => handlePackageSelect('One Minute ZATCA', 1, 'Minute')}
                                checked={selectedPackage?.packageName === 'One Minute ZATCA'}
                            />
                            <h2 className="text-lg font-semibold">One Minute ZATCA</h2>
                        </div>
                        <p className="text-sm mt-1 text-gray-400">1-minute subscription - $1</p>
                    </div> */}

                    <div className='flex items-center justify-center space-x-4'>

                        <div className="bg-white border border-slate-200 text-gray-800 p-4 rounded-md w-64">
                            <div className="flex items-center space-x-2">
                                <input
                                    type="radio"
                                    name="package"
                                    onChange={() => handlePackageSelect('Gold ZATCA', 100, 'Monthly')}
                                    checked={selectedPackage?.packageName === 'Gold ZATCA'}
                                />
                                <h2 className="text-lg font-semibold">Gold ZATCA</h2>
                            </div>
                            <p className="text-sm mt-1 text-gray-400">Monthly subscription - $100</p>
                        </div>

                        <div className="bg-white border border-slate-200 text-gray-800 p-4 rounded-md w-64">
                            <div className="flex items-center space-x-2">
                                <input
                                    type="radio"
                                    name="package"
                                    onChange={() => handlePackageSelect('Platinum ZATCA', 1000, 'Yearly')}
                                    checked={selectedPackage?.packageName === 'Platinum ZATCA'}
                                />
                                <h2 className="text-lg font-semibold">Platinum ZATCA</h2>
                            </div>
                            <p className="text-sm mt-1 text-gray-400">Yearly subscription - $1000</p>
                        </div>
                    </div>

                    <div className="text-gray-800 p-6 w-full">
                        <div className="space-y-3">
                            <div className="flex justify-between">
                                <span className="text-gray-600">Subtotal</span>
                                <span className="font-semibold">${subtotal.toFixed(2)}</span>
                            </div>
                            <hr className="my-3 border-gray-300" />
                            <div className="flex justify-between">
                                <span className="font-semibold text-lg">Total</span>
                                <span className="font-semibold text-lg">${total.toFixed(2)}</span>
                            </div>
                        </div>

                        <button
                            onClick={handleProceedToPayment}
                            className="w-full mt-6 bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-lg font-semibold"
                        >
                            Proceed to Payment
                        </button>

                        <p className="text-sm mt-4 text-gray-600">
                            One or more items in your cart require an account.
                            <a href="#" className="text-blue-600 hover:underline">Sign in or create an account now.</a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Checkout;
