import React from "react";
import logo from "../../assets/logo2.png";
import logo2 from "../../assets/logo2.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <section className="py-16 bg-white border-t border-slate-200 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-wrap lg:items-center -m-6">
            <Link to='/' className="w-full flex items-center justify-center md:w-auto p-6">
              <img src={logo} alt="BZSoftech Company Logo" className="w-72" />
            </Link>
            <div className="w-full md:w-1/2 p-6">
              <ul className="flex flex-wrap -m-5">
                <li className="p-5">
                  <Link
                    className="font-heading text-base text-gray-800 hover:text-gray-200"
                    to="/about"
                  >
                    About Us
                  </Link>
                </li>
                <li className="p-5">
                  <Link
                    className="font-heading text-base text-gray-800 hover:text-gray-200"
                    to="/pricing"
                  >
                    Pricing
                  </Link>
                </li>
                <li className="p-5">
                  <Link
                    className="font-heading text-base text-gray-800 hover:text-gray-200"
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-auto md:ml-auto p-6">
              <div className="flex flex-wrap items-center -m-1.5">
                <div className="w-auto p-1.5">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.5 6.66669L9.0755 11.0504C9.63533 11.4236 10.3647 11.4236 10.9245 11.0504L17.5 6.66669M4.16667 15.8334H15.8333C16.7538 15.8334 17.5 15.0872 17.5 14.1667V5.83335C17.5 4.91288 16.7538 4.16669 15.8333 4.16669H4.16667C3.24619 4.16669 2.5 4.91288 2.5 5.83335V14.1667C2.5 15.0872 3.24619 15.8334 4.16667 15.8334Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                <div className="w-auto p-1.5">
                  <h3 className="font-heading font-medium text-base text-gray-800">
                    info@bzosftech.com
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="py-9">
            <div className="border-b border-slate-200"></div>
          </div>
          <div className="flex flex-wrap items-center justify-between -m-6">
            <div className="w-auto p-6">
              <p className="text-sm text-gray-700">
                © Copyright 2024. All Rights Reserved by BZSoftech. Your trusted
                ZATCA solution provider.
              </p>
            </div>
            <div className="w-auto p-6">
              <div className="flex flex-wrap -m-6">
                <div className="w-auto p-6">
                  <Link
                    className="text-gray-700 hover:text-gray-400 text-sm"
                    to="#"
                  >
                    Privacy Policy
                  </Link>
                </div>
                <div className="w-auto p-6">
                  <Link
                    className="text-gray-700 hover:text-gray-400 text-sm"
                    to="#"
                  >
                    Terms & Conditions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
