import React from 'react';
import OnBoardingForm from './Form';

const OnBoard = () => {
    return (
        <>
            <div className='mb-8'>
                <h1 className='text-5xl font-light mb-2'>Onboarding</h1>
                <p className='text-md text-gray-600'>Onbaord your new device into Fatoora or ZATCA</p>
            </div>
            <OnBoardingForm></OnBoardingForm>
        </>
    );
};

export default OnBoard;