import { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../../../context/data';

const OtpPage = () => {
    const { user } = useUser();
    const { authURL } = useAuth();
    const [OTP, setOtp] = useState("");
    const [loading, setLoading] = useState(true);

    const fetchAndUpdateOtp = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch OTP');
            }

            const result = await response.json();
            const otpValue = result._id; // Treat `_id` as the OTP value
            setOtp(otpValue);

            await fetch(`${authURL}/update-user-stats?email=${user.primaryEmailAddress.emailAddress}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ OTP: otpValue }),
            });

        } catch (error) {
            console.error('Error fetching or updating OTP:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAndUpdateOtp();
    }, []);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert("Copied to clipboard");
        }).catch((error) => {
            console.error("Failed to copy text: ", error);
        });
    };

    return (
        <>
            <div>
                <div>
                    <h1 className='text-5xl font-light mb-6'>Authorization</h1>
                    <p className='text-justify'>
                        Signing and generating an invoice hash involves creating a unique hash of the invoice data and signing it with a private key...
                    </p>
                </div>

                {loading ? (
                    <div className="loading max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
                        <l-zoomies size="80" stroke="5" bg-opacity="0.1" speed="1.4" color="black"></l-zoomies>
                    </div>
                ) : (
                    <div className="mt-12 max-w-2xl mx-auto w-full">
                        <h1>Username</h1>
                        <div className="w-full h-12 mb-4 secFont flex items-center justify-between bg-white border border-gray-300 rounded-lg text-xl font-semibold px-4">
                            <span>{user.primaryEmailAddress.emailAddress}</span>
                            <button
                                onClick={() => copyToClipboard(user.primaryEmailAddress.emailAddress)}
                                className="text-blue-500"
                                aria-label="Copy Username"
                            >
                                📋
                            </button>
                        </div>
                        <h1>Password</h1>
                        <div className="w-full h-16  mb-4 secFont flex items-center justify-between bg-white border border-gray-300 rounded-lg text-xl font-light px-4">
                            <h1 className='text-3xl font-semibold'>{OTP}</h1>
                            <button
                                onClick={() => copyToClipboard(OTP)}
                                className="text-blue-500"
                                aria-label="Copy OTP"
                            >
                                📋
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default OtpPage;
