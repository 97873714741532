import React, { useState, useRef } from 'react';
import { Link, Outlet } from 'react-router-dom';

function ClientLayout() {
  const [environment, setEnvironment] = useState('simulation');
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleEnvChange = (value) => {
    setEnvironment(value);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='max-w-7xl mx-auto mt-32 flex mb-24'>
      <div className='w-1/4 bg-white p-4 border-r border-slate-200 py-2'>
        <nav className='secFont'>
          <div className='mb-2 relative'>
            <button
              onClick={toggleDropdown}
              className="w-full p-2 rounded border font-semibold text-sm border-slate-300 bg-white text-gray-800 flex justify-between items-center focus:outline-none"
            >
              {environment.charAt(0).toUpperCase() + environment.slice(1)}
              <span className={`mx-2 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}><i class="fa-duotone fa-solid fa-caret-down"></i></span>
            </button>

            {isOpen && (
              <div
                ref={selectRef}
                className="absolute z-10 mt-1 w-full bg-white border rounded shadow-lg"
              >
                <button
                  className={`block w-full text-left font-semibold text-sm p-2 hover:bg-slate-300 ${environment === 'simulation' ? 'bg-slate-200' : ''}`}
                  onClick={() => handleEnvChange('simulation')}
                >
                  Simulation Purpose
                </button>
                <button
                  className={`block w-full text-left font-semibold text-sm p-2 hover:bg-gray-200 ${environment === 'production' ? 'bg-gray-200' : ''}`}
                  onClick={() => handleEnvChange('production')}
                >
                  Production Purpose
                </button>
              </div>
            )}
          </div>

          <ul>
            {environment === 'simulation' && (
              <>
                <li className='mt-8'>
                  <Link to='/dashboard' class="flex text-sm font-semibold items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                    <span>Dashboard</span>
                  </Link>
                </li>

                <h3 class="mb-2 mt-8 text-sm text-gray-1000 font-semibold">General APIs</h3>
                <ul class="mb-8 text-md font-light">

                  <li>
                    <Link to='invoice-hashing' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Invoice Signing</span>
                    </Link>
                  </li>

                  <li>
                    <Link to='invoice-qr' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Invoice QR Code</span>
                    </Link>
                  </li>


                  <li>
                    <Link to='taxpayer' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Generate Taxpayer.csr</span>
                    </Link>
                  </li>

                </ul>

                <h3 class="mb-2 mt-8 text-sm text-gray-1000 font-semibold">Onboarding</h3>
                <ul class="mb-8 text-md font-light">

                  <li>
                    <Link to='onboarding' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Onboarding Device</span>
                    </Link>
                  </li>

                  <li>
                    <Link to='compliance' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Compliance</span>
                    </Link>
                  </li>


                  <li>
                    <Link to='production-pcsid' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Production PSCID</span>
                    </Link>
                  </li>

                </ul>

                <h3 class="mb-2 mt-8 text-sm text-gray-1000 font-semibold">Posting</h3>
                <ul class="mb-8 text-md font-light">

                  <li>
                    <Link to='clearance' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Invoice Clearance</span>
                    </Link>
                  </li>

                </ul>

                <h3 class="mb-2 mt-8 text-sm text-gray-1000 font-semibold">Reporting</h3>
                <ul class="mb-8 text-md font-light">

                  <li>
                    <Link to='reporting' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Invoice Reporting</span>
                    </Link>
                  </li>

                </ul>

              </>
            )}
            {environment === 'production' && (
              <>
                <li className='mt-8'>
                  <Link to='/dashboard' class="flex text-sm font-semibold items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                    <span>Dashboard</span>
                  </Link>
                </li>

                <h3 class="mb-2 mt-8 text-sm text-gray-1000 font-semibold">Auth</h3>
                <ul class="mb-8 text-md font-light">

                  <li>
                    <Link to='authorization' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Authorization</span>
                    </Link>
                  </li>

                </ul>


                <h3 class="mb-2 mt-8 text-sm text-gray-1000 font-semibold">General APIs</h3>
                <ul class="mb-8 text-md font-light">

                  <li>
                    <Link to='invoice-signing' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Invoice Signing</span>
                    </Link>
                  </li>

                  <li>
                    <Link to='invoice-qr-generator' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Invoice QR Code</span>
                    </Link>
                  </li>


                  <li>
                    <Link to='gen-taxpayer-csr' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Generate Taxpayer.csr</span>
                    </Link>
                  </li>

                </ul>

                <h3 class="mb-2 mt-8 text-sm text-gray-1000 font-semibold">Onboarding</h3>
                <ul class="mb-8 text-md font-light">

                  <li>
                    <Link to='onboard-device' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Onboarding Device</span>
                    </Link>
                  </li>

                  <li>
                    <Link to='compliance-prod' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Compliance</span>
                    </Link>
                  </li>


                  <li>
                    <Link to='production-prod' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Production PSCID</span>
                    </Link>
                  </li>

                </ul>

                <h3 class="mb-2 mt-8 text-sm text-gray-1000 font-semibold">Posting</h3>
                <ul class="mb-8 text-md font-light">

                  <li>
                    <Link to='clearance-prod' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Invoice Clearance</span>
                    </Link>
                  </li>

                </ul>

                <h3 class="mb-2 mt-8 text-sm text-gray-1000 font-semibold">Reporting</h3>
                <ul class="mb-8 text-md font-light">

                  <li>
                    <Link to='reporting-prod' class="flex text-sm font-medium items-center py-1 pr-4 text-gray-500 hover:text-blue-700">
                      <span>Invoice Reporting</span>
                    </Link>
                  </li>

                </ul>

              </>
            )}
          </ul>
        </nav>
      </div>

      <div className="w-full max-w-5xl mx-auto p-8 bg-slate-50 border border-slate-100 ml-4">
        <Outlet />
      </div>
    </div>
  );
}

export default ClientLayout;
