import React, { useState } from 'react';
import { useAuth } from '../../../../context/data';
import { useUser } from '@clerk/clerk-react';

const Production = () => {
    const { user } = useUser();
    const username = user.primaryEmailAddress.emailAddress;
    const { authURL, ID } = useAuth();
    const password = ID;

    const [binarySecurityToken, setBinarySecurityToken] = useState('');
    const [secret, setSecret] = useState('');
    const [requestID, setRequestID] = useState('');
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setResponse(null);

        const encodedCredentials = btoa(`${username}:${password}`);

        try {
            const res = await fetch(`${authURL}/production-pcsid`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${encodedCredentials}`,
                },
                body: JSON.stringify({
                    binarySecurityToken,
                    secret,
                    requestID
                })
            });

            const data = await res.json();

            if (!res.ok) {
                setError({
                    status: res.status,
                    message: data.error || 'An error occurred',
                    details: data.message || 'Unexpected error occurred',
                });
            } else {
                setResponse({
                    status: res.status,
                    message: 'Compliance check successful!',
                    data: data,
                });
            }
        } catch (err) {
            setError({
                status: 500,
                message: 'An unexpected error occurred',
                details: err.message,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center">
            <div className="w-full">
                <form onSubmit={handleSubmit} className="space-y-4">
                    <label className="block">
                        <span className="text-gray-700">Binary Security Token:</span>
                        <input
                            type="text"
                            className="mt-2 block w-full p-2 border border-gray-300 rounded-md outline-none"
                            value={binarySecurityToken}
                            onChange={(e) => setBinarySecurityToken(e.target.value)}
                            placeholder="Enter Binary Security Token"
                            required
                        />
                    </label>
                    <label className="block">
                        <span className="text-gray-700">Secret:</span>
                        <input
                            type="text"
                            className="mt-2 block w-full p-2 border border-gray-300 rounded-md outline-none"
                            value={secret}
                            onChange={(e) => setSecret(e.target.value)}
                            placeholder="Enter Secret"
                            required
                        />
                    </label>
                    <label className="block">
                        <span className="text-gray-700">Request ID:</span>
                        <input
                            type="text"
                            className="mt-2 block w-full p-2 border border-gray-300 rounded-md outline-none"
                            value={requestID}
                            onChange={(e) => setRequestID(e.target.value)}
                            placeholder="Enter Request ID"
                            required
                        />
                    </label>
                    <button
                        type="submit"
                        className="bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-lg transition duration-200 px-4 py-2 rounded disabled:opacity-50"
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Throw to Production'}
                    </button>
                </form>

                {response && (
                    <div className="mt-6 p-4 border border-green-400 rounded-md bg-green-50">
                        <h3 className="text-lg font-semibold text-green-700">Success (HTTP {response.status})</h3>
                        <p className="text-green-700 mb-2">{response.message}</p>
                        <pre className="text-sm bg-gray-100 p-2 rounded-md overflow-auto">
                            {JSON.stringify(response.data, null, 2)}
                        </pre>
                    </div>
                )}

                {error && (
                    <div className="mt-6 p-4 border border-red-400 rounded-md bg-red-50">
                        <h3 className="text-lg font-semibold text-red-700">Error (HTTP {error.status})</h3>
                        <p className="text-red-700 mb-2">{error.message}</p>
                        {error.details && (
                            <pre className="text-sm bg-gray-100 p-2 rounded-md overflow-auto">
                                {JSON.stringify(error.details, null, 2)}
                            </pre>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Production;
