import React, { useState } from 'react';
import { useAuth } from '../../../../context/data';
import { useUser } from '@clerk/clerk-react';

const Onboard = () => {
    const { user } = useUser();
    const username = user.primaryEmailAddress.emailAddress;
    const { authURL, ID } = useAuth();
    const password = ID;
    const [formData, setFormData] = useState({
        emailAddress: '',
        C: '',
        OU: '',
        O: '',
        CN: '',
        SN: '',
        UID: '',
        title: '',
        registeredAddress: '',
        businessCategory: '',
        OTP: '', // Add OTP field here
    });
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setResponse(null);
        setIsLoading(true);

        const encodedCredentials = btoa(`${username}:${password}`);

        try {
            const res = await fetch(`${authURL}/onboard`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${encodedCredentials}`,
                },
                body: JSON.stringify(formData),
            });

            const data = await res.json();

            if (!res.ok) {
                setError({
                    status: res.status,
                    message: data.message || 'An error occurred',
                    details: data.error || 'Please check your input fields and try again.'
                });
            } else {
                setResponse({
                    status: res.status,
                    message: 'CSR generated successfully!',
                    data: data.csr,
                });
            }
        } catch (err) {
            setError({
                status: 500,
                message: 'An unexpected error occurred',
                details: err.message
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center">
            <div className="w-full">
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
                        {Object.keys(formData).map((field) => (
                            <label key={field} className="block">
                                <span className="text-gray-700 font-medium capitalize">{field}</span>
                                <input
                                    type={field === 'OTP' ? 'password' : 'text'} // Make OTP a password input for privacy
                                    name={field}
                                    value={formData[field]}
                                    onChange={handleChange}
                                    className="mt-2 block w-full p-3 border border-gray-300 rounded-md outline-none"
                                    placeholder={`Enter ${field}`}
                                    required
                                />
                            </label>
                        ))}
                    </div>

                    <button
                        type="submit"
                        className="w-full py-2 bg-gradient-to-b from-blue-500 to-blue-600 text-white font-semibold rounded-md hover:shadow-lg transition duration-200 disabled:opacity-50"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Onboarding...' : 'Onboard Device'}
                    </button>
                </form>

                {response && (
                    <div className="mt-6 p-4 border border-green-400 rounded-md bg-green-50">
                        <h3 className="text-lg font-semibold text-green-700">Success (HTTP {response.status})</h3>
                        <p className="text-green-700 mb-2">{response.message}</p>
                        <pre className="text-sm bg-gray-100 p-2 rounded-md overflow-auto">
                            {JSON.stringify(response.data, null, 2)}
                        </pre>
                    </div>
                )}

                {error && (
                    <div className="mt-6 p-4 border border-red-400 rounded-md bg-red-50">
                        <h3 className="text-lg font-semibold text-red-700">Error (HTTP {error.status})</h3>
                        <p className="text-red-700 mb-2">{error.message}</p>
                        {error.details && (
                            <pre className="text-sm bg-gray-100 p-2 rounded-md overflow-auto">
                                {error.details}
                            </pre>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Onboard;
