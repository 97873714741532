import React from 'react';
import ReportingForm from './Form';

const Reporting = () => {
    return (
        <>
            <div className='mb-8'>
                <h1 className='text-5xl font-light mb-2'>Reporting</h1>
                <p className='text-md text-gray-600'>Upload a base64 of an XML and get it signed version.</p>
            </div>
            <ReportingForm></ReportingForm>
        </>
    );
};

export default Reporting;