import React, { useEffect, useState } from 'react';
import { SignedIn, useUser } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/data';
import SubscriptionTime from './SubscriptionTime';

function PremiumProtectedRoute({ Component }) {
    const { authURL } = useAuth();
    const { isSignedIn, user } = useUser();
    const [status, setStatus] = useState(null);
    const [showComponent, setShowComponent] = useState(false);
    const [showSubscriptionTime, setShowSubscriptionTime] = useState(false);

    useEffect(() => {
        const getStatus = async () => {
            if (isSignedIn && user) {
                try {
                    const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`);
                    if (response.status === 404) {
                        console.error("Status not found");
                        return;
                    }
                    const data = await response.json();
                    console.log("API Response:", data.status);
                    setStatus(data.status);
                } catch (error) {
                    console.error("Error fetching status:", error);
                }
            }
        };

        getStatus();
    }, [isSignedIn, user, authURL]);

    useEffect(() => {
        if (status !== 'premium') {
            const timer = setTimeout(() => {
                setShowSubscriptionTime(true);
            }, 2000);
            return () => clearTimeout(timer);
        } else {
            const timer = setTimeout(() => {
                setShowComponent(true);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [status]);

    if (!isSignedIn) {
        return <Navigate to="/login" />;
    }

    if (status !== 'premium' && showSubscriptionTime) {
        return (
            <div className="max-w-7xl mx-auto flex flex-col items-center justify-center h-screen">
                <SubscriptionTime />
            </div>
        );
    }

    if (status === 'premium' && showComponent) {
        return (
            <SignedIn>
                <Component />
            </SignedIn>
        );
    }

    return null; // Optional: you can return a loading spinner here if desired
}

export default PremiumProtectedRoute;
