import React from 'react';
import ClearanceForm from './Form';

const Clearance = () => {
    return (
        <>
            <div className='mb-8'>
                <h1 className='text-5xl font-light mb-2'>Clearance</h1>
                <p className='text-md text-gray-600'>Onboard your device to get started with Invoice Clearance and Production processes.</p>
            </div>
            <ClearanceForm></ClearanceForm>
        </>
    );
};

export default Clearance;