import React from 'react';
import hero from '../../assets/hero.webp'
import zatca from '../../assets/zatca.png'
import { Link } from 'react-router-dom';

const HeroSection = () => {
    return (
        <section className="relative">
            <section className="pt-32 pb-20 bg-white overflow-hidden">
                <div className="max-w-7xl mx-auto h-full py-4">

                    <div className="flex flex-wrap lg:items-center pb-2 -m-8">
                        <div className="w-full md:w-1/2 p-8">
                            <div className="max-w-max mx-auto md:mr-0 rounded-3xl">
                                <img className="mx-auto" src={hero} alt="ZATCA API Integration" />
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 p-8">
                            <div className="md:max-w-lg">
                                <span className="inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest secFont">Developed by BZ Softech</span>
                                <h1 className="mb-4 text-3xl md:text-5xl text-gray-900 font-semibold tracking-tight">
                                    Explore leading <span className='text-blue-600'>ERPs</span> &
                                </h1>
                                <h1 className="mb-4 text-3xl md:text-5xl text-gray-900 font-semibold tracking-tight">
                                    <span className='text-blue-600'>E-Invoicing</span> APIs Solution
                                </h1>
                                <p className="mb-8 font-light">Easily integrate your existing Enterprise Resource Planning (ERP) systems, Point of Sale (POS) solutions, and applications with ZATCA APIs. Sign up today to try the ZATCA API with a free trial!</p>
                                <div className="flex flex-wrap mb-11 mt-4">
                                    <div className="w-full md:w-auto">
                                        <Link to='/contact' className='mr-4 text-gray-900 bg-white border px-4 py-2 secFont text-sm font-semibold rounded'>Get a Free Consultation</Link>
                                    </div>
                                    <div className="w-full md:w-auto md:my-0 my-8">
                                        <Link to='/dashboard' className='mr-4 text-white  bg-gray-900 px-4 py-2 secFont text-sm font-semibold rounded'>Try the ZATCA APIs Now</Link>
                                    </div>
                                </div>
                                <div className="flex flex-wrap w-full">
                                    <div className="flex items-start justify-center space-x-4 w-full h-full">
                                        <img src={zatca} alt="ZATCA Approved Provider" className='w-20' />
                                        <div>
                                            <h1 className="mb-1 font-semibold text-gray-900 tracking-tight">A ZATCA Approved Solution Provider</h1>
                                            <p className="text-sm">100% ZATCA Compliance: A Saudi-based solution tailored for Saudi businesses.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default HeroSection;
