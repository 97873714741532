import React from 'react';
import InvoiceQRForm from './Form';

const InvoiceQR = () => {
    return (
        <>
            <div className='mb-8'>
                <h1 className='text-5xl font-light mb-2'>Generate QR Code - Invoice</h1>
                <p className='text-md text-gray-600'>Upload a base64 of an XML and get it QR Code.</p>
            </div>
            <InvoiceQRForm></InvoiceQRForm>
        </>
    );
};

export default InvoiceQR;