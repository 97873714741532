import React, { useState } from 'react';
import QRCode from 'qrcode';
import { useAuth } from '../../../../context/data';
import { useUser } from '@clerk/clerk-react';

const QRCodeGenerator = () => {
    const { user } = useUser();
    const username = user.primaryEmailAddress.emailAddress;
    const { authURL, ID } = useAuth();
    const password = ID;

    const [base64Data, setBase64Data] = useState('');
    const [qrCode, setQRCode] = useState('');
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setResponse(null);
        setQRCode('');
        setIsLoading(true);

        const encodedCredentials = btoa(`${username}:${password}`);

        try {
            const res = await fetch(`${authURL}/invoice-qr`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${encodedCredentials}`
                },
                body: JSON.stringify({ base64: base64Data })
            });

            const data = await res.json();
            setIsLoading(false);
            if (!res.ok) {
                setError({ status: res.status, message: data.error || 'An error occurred', details: data.message });
                return;
            }

            // Generate QR code image
            setResponse({
                status: res.status,
                message: 'QR Code generated successfully!',
                data: data
            });

            QRCode.toDataURL(data.qrCode, { width: 256 }, (err, url) => {
                if (err) {
                    console.error('Failed to generate QR code image', err);
                    setError({ message: 'Failed to generate QR code image.' });
                    return;
                }
                setQRCode(url);
            });
        } catch (err) {
            setIsLoading(false);
            setError({ status: 500, message: 'An unexpected error occurred', details: err.message });
        }
    };

    return (
        <div className="flex items-center justify-center">
            <div className="w-full">
                <form onSubmit={handleSubmit} className="space-y-4">
                    <label className="block">
                        <span className="text-gray-700 text-2xl font-medium">Simulate Now:</span>
                        <textarea
                            className="mt-2 block w-full p-4 border h-72 border-gray-300 rounded-md outline-none"
                            value={base64Data}
                            onChange={(e) => setBase64Data(e.target.value)}
                            placeholder="Enter base64 data here"
                            required
                        />
                    </label>
                    <button
                        type="submit"
                        className="bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-lg transition duration-200 px-4 py-2 rounded disabled:opacity-50"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Generating...' : 'Generate QR Code'}
                    </button>
                </form>

                {response && (
                    <div className="mt-6 p-4 border border-green-400 rounded-md bg-green-50">
                        <h3 className="text-lg font-semibold text-green-700">Success (HTTP {response.status})</h3>
                        <p className="text-green-700 mb-2">{response.message}</p>
                        <div className="mt-4">
                            <p className="text-gray-700 font-semibold">Response Data:</p>
                            <pre className="text-sm bg-gray-100 p-2 rounded-md overflow-auto">
                                {JSON.stringify(response.data, null, 2)}
                            </pre>
                        </div>
                        {qrCode && (
                            <img src={qrCode} alt="QR Code" className="border p-2 bg-white rounded shadow-lg mt-2" />
                        )}
                    </div>
                )}

                {error && (
                    <div className="mt-6 p-4 border border-red-400 rounded-md bg-red-50">
                        <h3 className="text-lg font-semibold text-red-700">Error (HTTP {error.status})</h3>
                        <p className="text-red-700 mb-2">{error.message}</p>
                        {error.details && (
                            <pre className="text-sm bg-gray-100 p-2 rounded-md overflow-auto">
                                {error.details}
                            </pre>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default QRCodeGenerator;
