import React from 'react';
import { Helmet } from 'react-helmet';
import HeroSection from './HeroSection';
import Features from './Features';
import Stats from './Stats';
import Services from './Services';

const Homepage = () => {
    return (
        <div className='px-0 md:mx-0'>
            <Helmet>
                <title>ZATCA API – E-Invoicing & Compliance Services for Saudi Arabia</title>
                <meta name="description" content="API services for ZATCA compliance including e-invoicing, hashing, signing invoices, and more for Saudi businesses." />
                <meta name="keywords" content="ZATCA API, e-invoicing, compliance services, Saudi Arabia, invoice hashing, digital signing" />
                <meta property="og:title" content="ZATCA API – E-Invoicing & Compliance Services for Saudi Arabia" />
                <meta property="og:description" content="API services for ZATCA compliance including e-invoicing, hashing, signing invoices, and more for Saudi businesses." />
                <meta property="og:url" content="https://yourwebsite.com" />
                <meta property="og:type" content="website" />
            </Helmet>

            <HeroSection />
            <Stats />
            <Features />
            <Services />
        </div>
    );
};

export default Homepage;
