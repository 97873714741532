import React from 'react';
import { Link } from 'react-router-dom';

const ProductionProd = () => {
    return (
        <div className='flex flex-col space-y-8'>

            <div>
                <h1 className='text-5xl font-light mb-2'>Production PCSID</h1>
                <p className='text-md text-gray-600'>Push your Invoices into Production for furthur processing.</p>
            </div>


            <div className="w-full">

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                    <p className='text-sm text-gray-600 mb-2'>Upload a Base64 of an XML then it will return the hash of the processed invoice.</p>
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code className="text-blue-400">
                            <span className="text-green-400">https://</span>
                            <span className="text-yellow-400">api.</span>
                            <span className="text-yellow-300">zatca</span>
                            <span className="text-yellow-400">.co</span>
                            <span className="text-blue-400">/invoice-sign</span>
                        </code>
                    </pre>
                </div>

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-1">Options</h3>
                    <p className='text-sm text-gray-600 mb-2'>You can find Authorization Credentials from here: <Link to="/dashboard/authorization" className='pl-2 text-blue-500 underline'>Click here</Link></p>
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code>
                            <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                            <br />
                            {'}'}
                        </code>
                    </pre>

                </div>

                <div className="mb-4">
                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                        <p className='text-sm text-gray-600 mb-2'>Send these data in req.body.</p>
                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                            <code>
                                <span className="text-blue-400">{'{ '}</span>
                                <span className="text-green-400">binarySecurityToken, secret, requestID</span>
                                <span className="text-blue-400">{' },'}</span>
                            </code>
                        </pre>
                    </div>
                </div>

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Response</h3>
                </div>
                <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code>
                            <span className="text-red-400">{'{ '}</span>
                            <span className="text-yellow-400">requestID </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`20433161194`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="text-yellow-400">tokenType </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-x509-token-profile-1.0#X509v3"`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="text-yellow-400">dispositionMessage </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"ISSUED"`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="text-yellow-400">binarySecurityToken </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"mNVBTRVBvd0loQU9OWVYzaFyZFM3akVya29OTUhPT0ZQN3M=..."`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="text-yellow-400">secret </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"8IxvCazG+vyVydJNd3+zCvQpLDfhaiQIlBTtAs3YU3Y="`}</span>
                            <br />
                            <span className="text-red-400">{'} '}</span>
                        </code>
                    </pre>

                </div>
            </div>
        </div>
    );
};

export default ProductionProd;