import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ClerkProvider, ClerkLoading, ClerkLoaded } from '@clerk/clerk-react';
import { Helmet } from 'react-helmet';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/data';

const clerkPubkey = 'pk_test_YWxsb3dpbmctcGxhdHlwdXMtMTIuY2xlcmsuYWNjb3VudHMuZGV2JA';

const root = ReactDOM.createRoot(document.getElementById('root'));

const LoadingFallback = () => (
  <div className="loading max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
    <l-zoomies
      size="80"
      stroke="5"
      bg-opacity="0.1"
      speed="1.4"
      color="black"
    ></l-zoomies>
  </div>
);

root.render(
  <ClerkProvider publishableKey={clerkPubkey} afterSignInUrl="/dashboard" afterSignUpUrl="/dashboard">
    <AuthProvider>
      <React.StrictMode>
        <BrowserRouter>
          <Helmet>
            <title>ZATCA API | E-Invoicing & Compliance Solutions</title>
            <meta name="description" content="Reliable solutions for ZATCA compliance, including e-invoicing and digital signing services." />
            <meta name="robots" content="index, follow" />
          </Helmet>
          <ClerkLoading>
            <LoadingFallback />
          </ClerkLoading>
          <ClerkLoaded>
            <App />
          </ClerkLoaded>
        </BrowserRouter>
      </React.StrictMode>
    </AuthProvider>
  </ClerkProvider>
);

reportWebVitals(console.log);
