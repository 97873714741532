import React from 'react'

function Stats() {
    return (
        <div>
            <section className="py-24 bg-gray-900">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full lg:w-1/3 p-4">
                            <div className="bg-white rounded py-6 px-4">
                                <h2 className="text-gray-900 text-5xl lg:text-6xl font-bold font-heading text-center mb-4">500+</h2>
                                <p className="text-gray-700 font-medium text-center">Partnered with Over 500 Trusted Companies for Seamless Integration</p>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 p-4">
                            <div className="bg-white rounded py-6 px-4">
                                <h2 className="text-gray-900 text-5xl lg:text-6xl font-bold font-heading text-center mb-4">10k+</h2>
                                <p className="text-gray-700 font-medium text-center">10,000+ Happy Customers Benefiting from ZATCA Compliance</p>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 p-4">
                            <div className="bg-white rounded py-6 px-4">
                                <h2 className="text-gray-900 text-5xl lg:text-6xl font-bold font-heading text-center mb-4">1M+</h2>
                                <p className="text-gray-700 font-medium text-center">Over 1 Million Invoices Processed Monthly with ZATCA Solutions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Stats
