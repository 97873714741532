import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../context/data';

function UserStats() {
    const { authURL } = useAuth();
    const { user, isSignedIn } = useUser();
    const [status, setStatus] = useState(null);
    const [packageData, setPackageData] = useState(null);
    const [purchasedOn, setPurchasedOn] = useState(null);
    const [purchasedExpiry, setPurchasedExpiry] = useState(null);

    const getUserStats = async () => {
        if (isSignedIn && user) {
            try {
                const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`);
                if (response.status === 404) {
                    console.error("User stats not found");
                    return;
                }
                const data = await response.json();
                console.log("API Response:", data);

                // Setting state with fetched data
                setStatus(data.status);
                setPackageData(data.package);
                setPurchasedOn(formatDate(data.purchasedOn));
                setPurchasedExpiry(formatDate(data.purchasedExpiry));
            } catch (error) {
                console.error("Error fetching user stats:", error);
            }
        }
    };

    // Function to format date to DD-MM-YYYY
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        getUserStats();
    }, [isSignedIn, user]);

    return (
        <div>
            {status && (
                <div>
                    <p className='text-end font-semibold bg-red-600 px-8 py-2 rounded-md text-white'>{purchasedExpiry}</p>
                </div>
            )}
            <h2 className='text-red-600 text-center'>Next Billing Date</h2>
        </div>
    );
}

export default UserStats;
