import React, { useState } from 'react';
import { useAuth } from '../../../../context/data';
import { useUser } from '@clerk/clerk-react';

const InvoiceProcessor = () => {
    const { user } = useUser();
    const username = user.primaryEmailAddress.emailAddress;
    const { authURL, ID } = useAuth();
    const password = ID;
    const [binarySecurityToken, setBinarySecurityToken] = useState('');
    const [secret, setSecret] = useState('');
    const [base64Xml, setBase64Xml] = useState('');
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setResponse(null);
        const encodedCredentials = btoa(`${username}:${password}`);
        try {
            const res = await fetch(`${authURL}/clearance`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${encodedCredentials}`,
                },
                body: JSON.stringify({ binarySecurityToken, secret, base64Xml }),
            });

            if (res.status === 409) {
                const data = await res.json();
                setError(data.error);
            } else if (res.status === 500) {
                setError('Internal server error. Please try again later.');
            } else if (res.status === 200) {
                const data = await res.json();
                setResponse(data);
            }
        } catch (err) {
            setError('An error occurred. Please check your input and try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-full">
            <form onSubmit={handleSubmit} className="mt-4">
                <label className="block text-gray-600">Binary Security Token</label>
                <input
                    type="text"
                    value={binarySecurityToken}
                    onChange={(e) => setBinarySecurityToken(e.target.value)}
                    className="w-full p-2 mt-1 border rounded-md"
                    required
                />

                <label className="block mt-4 text-gray-600">Secret</label>
                <input
                    type="text"
                    value={secret}
                    onChange={(e) => setSecret(e.target.value)}
                    className="w-full p-2 mt-1 border rounded-md"
                    required
                />

                <label className="block mt-4 text-gray-600">Base64 XML</label>
                <textarea
                    value={base64Xml}
                    onChange={(e) => setBase64Xml(e.target.value)}
                    className="w-full p-2 mt-1 border rounded-md"
                    required
                ></textarea>

                <button
                    type="submit"
                    className={`w-full mt-6 p-2 text-white rounded-md ${loading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
                        }`}
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Submit'}
                </button>
            </form>

            {response && (
                <div className="mt-6 p-4 bg-green-100 text-green-800 rounded-md">
                    <h2 className="font-semibold">Success:</h2>
                    <pre className="whitespace-pre-wrap">{JSON.stringify(response, null, 2)}</pre>
                </div>
            )}

            {error && (
                <div className="mt-6 p-4 bg-red-100 text-red-800 rounded-md">
                    <h2 className="font-semibold">Error:</h2>
                    <pre className="whitespace-pre-wrap">{JSON.stringify(error, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default InvoiceProcessor;
