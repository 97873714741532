import { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [ID, setID] = useState();
    const { user } = useUser();
    const authURL = `https://api.emdadenergy.net/api`;
    // const authURL = `http://localhost:8910/api`

    const fetchUserID = async () => {
        try {
            const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch OTP');
            }

            const result = await response.json();
            setID(result._id);
        } catch (error) {
            console.error('Error fetching  ID:', error);
        } finally {
        }
    };
    
    useEffect(() => {
        fetchUserID();
    }, [fetchUserID])
    return (
        <AuthContext.Provider value={{ authURL, ID }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
}