import React, { useState } from "react";
import {
  SignedIn,
  SignedOut,
  UserButton,
  SignUpButton,
} from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo2.png";
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="fixed top-0 left-0 right-0 z-20 border-b border-slate-200 bg-white">
      <div className="px-4 md:px-6 lg:px-8">
        <div className="flex items-center justify-between max-w-7xl mx-auto">
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-end py-4">
              <img
                src={logo}
                alt="Logo Text"
                className="hidden md:block w-72 h-auto ml-2"
              />
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={toggleMenu}
            className="md:hidden p-2 rounded-md text-gray-700 hover:bg-gray-100"
          >
            {isMenuOpen ? (
              <i class="fa-solid fa-xmark" />
            ) : (
              <i class="fa-solid fa-bars" />
            )}
          </button>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center justify-end space-x-4 flex-grow">
            <nav className="flex items-center justify-center space-x-8 text-sm font-medium secFont list-none">
              <li>
                <Link to="/about" className="hover:text-gray-600">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/pricing" className="hover:text-gray-600">
                  Pricing
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-gray-600">
                  Contact Us
                </Link>
              </li>
            </nav>

            <div className="border-l border-slate-200 pl-4 flex items-center">
              <SignedOut>
                <SignUpButton mode="modal">
                  <button className="bg-gray-900 text-white px-4 py-2 secFont text-sm font-semibold rounded hover:bg-gray-800">
                    Get Started Now
                  </button>
                </SignUpButton>
              </SignedOut>

              <SignedIn>
                <Link
                  to="/dashboard"
                  className="mr-4 text-gray-900 bg-white border px-4 py-2 secFont text-sm font-semibold rounded hover:bg-gray-50"
                >
                  Dashboard
                </Link>
                <UserButton />
              </SignedIn>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div className={`md:hidden ${isMenuOpen ? "block" : "hidden"}`}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link
              to="/about"
              className="block px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 rounded-md"
              onClick={() => setIsMenuOpen(false)}
            >
              About Us
            </Link>
            <Link
              to="/pricing"
              className="block px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 rounded-md"
              onClick={() => setIsMenuOpen(false)}
            >
              Pricing
            </Link>
            <Link
              to="/contact"
              className="block px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 rounded-md"
              onClick={() => setIsMenuOpen(false)}
            >
              Contact Us
            </Link>

            <div className="pt-4 pb-3 border-t border-gray-200">
              <SignedOut>
                <div className="px-3">
                  <SignUpButton mode="modal">
                    <button
                      className="w-full bg-gray-900 text-white px-4 py-2 secFont text-sm font-semibold rounded hover:bg-gray-800"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Get Started Now
                    </button>
                  </SignUpButton>
                </div>
              </SignedOut>

              <SignedIn>
                <div className="px-3 space-y-3">
                  <Link
                    to="/dashboard"
                    className="block text-center text-gray-900 bg-white border px-4 py-2 secFont text-sm font-semibold rounded hover:bg-gray-50"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Dashboard
                  </Link>
                  <div className="flex justify-center">
                    <UserButton />
                  </div>
                </div>
              </SignedIn>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
