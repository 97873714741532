import React from 'react'
import bg from '../../assets/bg.png'
import { Link } from 'react-router-dom'

function Features() {
    return (
        <div>
            <section className="max-w-7xl mx-auto py-12 overflow-hidden">
                <div className="container px-4 mx-auto">
                    <h2 className="secFont mb-12 text-3xl md:text-6xl tracking-tighter">Exclusive Features of ZATCA API Integration</h2>
                    <div className="flex flex-wrap -m-3">
                        <div className="w-full md:w-1/2 p-3">
                            <div className="relative p-8 h-full overflow-hidden rounded-lg">
                                <div className="relative z-10 flex flex-col justify-between h-full">
                                    <div className="mb-24">
                                        <h3 className="text-4xl md:text-6xl text-white">API-Based Integration for Enhanced Automation</h3>
                                    </div>
                                    <div className="block">
                                        <p className="mb-6 text-xl text-white tracking-tight">Experience seamless API-based integration that connects your existing systems for real-time data exchange, significantly improving automation, operational efficiency, and accuracy.</p>
                                        <Link to='/dashboard/invoice-signing' className="inline-block px-8 py-4 font-semibold bg-white hover:bg-gray-100 tracking-tight rounded focus:ring-4 focus:ring-indigo-300 transition duration-300">Get Started Now</Link>
                                    </div>
                                </div>
                                <img className="absolute top-0 left-0 w-full h-full object-cover" src={bg} alt="API Integration Background" />
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 p-3">
                            <div className="flex flex-col justify-between p-8 h-full bg-black overflow-hidden rounded-lg">
                                <div className="mb-24">
                                    <h3 className="font-heading text-4xl md:text-6xl text-white">Streamlined Manual Pre-Made Forms</h3>
                                </div>
                                <div className="block">
                                    <p className="mb-6 text-xl text-white tracking-tight">Utilize our manual pre-made forms designed for efficient data entry. This feature ensures data consistency and significantly reduces the risk of errors, enhancing user experience and compliance.</p>
                                    <Link to='/dashboard/invoice-hashing' className="inline-block px-8 py-4 text-white font-semibold bg-gray-700 hover:bg-gray-800 tracking-tight rounded focus:ring-4 focus:ring-indigo-300 transition duration-300">Get Started Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Features
