import React from "react";

function Services() {
  return (
    <div className="max-w-7xl mx-auto px-3 py-24">
      <h2 className="secFont mb-12 text-4xl md:text-6xl tracking-tighter">
        Why Choose Our ZATCA API Integration Services?
      </h2>

      <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
        <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
          <span className="inline-block rounded-lg p-3">
            <i className="fa-duotone fa-solid fa-shield-check fa-2xl"></i>
          </span>
          <h2 className="mt-2 font-semibold text-lg">
            Fully Compliant Solutions
          </h2>
          <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
            As an approved ZATCA solution provider, we guarantee 100%
            audit-proof invoicing and compliance with local regulations.
          </p>
        </div>

        <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
          <span className="inline-block rounded-lg p-3">
            <i className="fa-duotone fa-solid fa-file-certificate fa-2xl"></i>
          </span>
          <h2 className="mt-2 font-semibold text-lg">
            Audited & Certified Services
          </h2>
          <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
            Our customers' setups, right from ZATCA Wave 1, are fully audited
            and certified, ensuring the highest standards of service.
          </p>
        </div>

        <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
          <span className="inline-block rounded-lg p-3">
            <i className="fa-duotone fa-solid fa-cloud-arrow-up fa-2xl"></i>
          </span>
          <h2 className="mt-2 font-semibold text-lg">
            Flexible Deployment Options
          </h2>
          <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
            Choose between our cloud-based SAAS solution or deploy on your own
            server, offering the flexibility to suit your business needs.
          </p>
        </div>

        <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
          <span className="inline-block rounded-lg p-3">
            <i className="fa-duotone fa-solid fa-calendar-days fa-2xl"></i>
          </span>
          <h2 className="mt-2 font-semibold text-lg">Rapid Implementation</h2>
          <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
            Our ZATCA integration allows you to go live in just one day,
            ensuring a fast and smooth transition for your business.
          </p>
        </div>

        <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
          <span className="inline-block rounded-lg p-3">
            <i className="fa-duotone fa-solid fa-gear fa-2xl"></i>
          </span>
          <h2 className="mt-2 font-semibold text-lg">
            Comprehensive Packaged Solutions
          </h2>
          <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
            Our tailored solutions are designed for SAP, Oracle, Microsoft
            Dynamics, Infor, BAAN, SAGE, and other ERP/POS systems.
          </p>
        </div>

        <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
          <span className="inline-block rounded-lg p-3">
            <i className="fa-duotone fa-solid fa-gauge fa-2xl"></i>
          </span>
          <h2 className="mt-2 font-semibold text-lg">
            High-Performance Processing
          </h2>
          <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
            Our platform can handle millions of invoices daily, ensuring
            reliable performance and scalability for your growing business.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
