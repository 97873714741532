import React from "react";
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

const Pricing = () => {
  return (
    <div>
      <Helmet>
        <title>
          Pricing Plans – ZATCA API | Affordable Compliance Solutions
        </title>
        <meta
          name="description"
          content="Explore ZATCA API's flexible pricing plans designed for businesses in Saudi Arabia. Get the best value for e-invoicing, invoice hashing, and digital signing services."
        />
        <meta
          name="keywords"
          content="ZATCA API pricing, compliance solutions, e-invoicing pricing, invoice hashing costs, digital signing fees"
        />
        <meta property="og:title" content="Pricing Plans – ZATCA API" />
        <meta
          property="og:description"
          content="Find the perfect pricing plan for your business needs with ZATCA API's transparent pricing for compliance services."
        />
        <meta property="og:url" content="https://yourwebsite.com/pricing" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section
        x-data="{ toggle: true }"
        className="relative py-20 overflow-hidden bg-white"
      >
        <img
          className="absolute top-0 right-0 -mr-32 md:-mr-0"
          src="saturn-assets/images/pricing/circle-star.png"
          alt=""
        />
        <div className="relative container px-4 mx-auto">
          <div className="max-w-2xl lg:max-w-5xl mx-auto text-center">
            <div className="max-w-[80rem] mx-auto overflow-hidden">
              <div className="max-w-7xl w-full relative">
                <div className="p-10 md:p-16">
                  {/* Header */}
                  <div className="text-center">
                    <h1 className="text-5xl text-gray-800 font-bold">
                      Choose a
                      <span className="text-blue-600 mx-2">Plan</span> for your work limit
                    </h1>
                    <p className="mt-4 text-lg text-gray-500 max-w-2xl mx-auto">
                      ZATCA API leads the way in e-invoicing and ERP solutions,
                      tailored for Saudi businesses. Our solutions blend simplicity
                      with quality.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="max-w-7xl mx-auto">
            <section class="text-gray-600 body-font overflow-hidden w-full">
              <div class="container px-5 mx-auto flex items-center justify-center space-x-4 w-full">

                <div class="p-4 w-full">
                  <div class="h-full p-6 rounded-lg border-2 border-blue-500 flex flex-col relative overflow-hidden">
                    <span class="bg-blue-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">GOLD</span>
                    <h2 class="text-sm tracking-widest title-font mb-1 font-medium">PRO</h2>
                    <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                      <span>$99</span>
                      <span class="text-lg ml-1 font-normal text-gray-500">/month</span>
                    </h1>
                    <p class="flex items-center text-gray-600 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-white rounded-full flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>Unlimited Tool Simulation
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-white rounded-full flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>Unlimited API integration
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-white rounded-full flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>Unlimited APIs consumption
                    </p>
                    <p class="flex items-center text-gray-600 mb-6">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-white rounded-full flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>Hassle Free Month
                    </p>
                    <Link to='/checkout' class="flex items-center mt-auto text-white bg-blue-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-blue-600 rounded">Buy Now
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </Link>
                  </div>
                </div>

                <div class="p-4 w-full">
                  <div class="h-full p-6 rounded-lg border-2 border-blue-500 flex flex-col relative overflow-hidden">
                    <span class="bg-blue-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">PLATINUM</span>
                    <h2 class="text-sm tracking-widest title-font mb-1 font-medium">PRO</h2>
                    <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                      <span>$999</span>
                      <span class="text-lg ml-1 font-normal text-gray-500">/year</span>
                    </h1>
                    <p class="flex items-center text-gray-600 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-white rounded-full flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>Unlimited Tool Simulation
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-white rounded-full flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>Unlimited API integration
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-white rounded-full flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>Unlimited APIs consumption
                    </p>
                    <p class="flex items-center text-gray-600 mb-6">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-white rounded-full flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>Hassle Free Month
                    </p>
                    <Link to='/checkout' class="flex items-center mt-auto text-white bg-blue-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-blue-600 rounded">Buy Now
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </Link>
                  </div>
                </div>


              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
