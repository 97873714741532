import React, { useState } from 'react';

function Demo() {
    const initialVisibilityState = {
        2024: { quarter: null, month: null },
        2025: { quarter: null, month: null },
    };

    const [expandedView, setExpandedView] = useState(initialVisibilityState);

    const toggleQuarter = (year, quarter) => {
        setExpandedView((prev) => ({
            ...prev,
            [year]: {
                quarter: prev[year].quarter === quarter ? null : quarter,
                month: null, // Reset month when switching quarter
            },
        }));
    };

    const toggleMonth = (year, month) => {
        setExpandedView((prev) => ({
            ...prev,
            [year]: {
                ...prev[year],
                month: prev[year].month === month ? null : month,
            },
        }));
    };

    // Data structure for years with quarters, months, and items within each month
    const data = [
        {
            year: 2024,
            quarters: {
                Q1: {
                    January: ['Item 1', 'Item 2', 'Item 3'],
                    February: ['Item 4', 'Item 5', 'Item 6'],
                    March: ['Item 7', 'Item 8', 'Item 9'],
                },
                Q2: {
                    April: ['Item 10', 'Item 11', 'Item 12'],
                    May: ['Item 13', 'Item 14', 'Item 15'],
                    June: ['Item 16', 'Item 17', 'Item 18'],
                },
                Q3: {
                    July: ['Item 19', 'Item 20', 'Item 21'],
                    August: ['Item 22', 'Item 23', 'Item 24'],
                    September: ['Item 25', 'Item 26', 'Item 27'],
                },
                Q4: {
                    October: ['Item 28', 'Item 29', 'Item 30'],
                    November: ['Item 31', 'Item 32', 'Item 33'],
                    December: ['Item 34', 'Item 35', 'Item 36'],
                },
            },
        },
        {
            year: 2025,
            quarters: {
                Q1: {
                    January: ['Item A1', 'Item A2', 'Item A3'],
                    February: ['Item B1', 'Item B2', 'Item B3'],
                    March: ['Item C1', 'Item C2', 'Item C3'],
                },
                Q2: {
                    April: ['Item D1', 'Item D2', 'Item D3'],
                    May: ['Item E1', 'Item E2', 'Item E3'],
                    June: ['Item F1', 'Item F2', 'Item F3'],
                },
                Q3: {
                    July: ['Item G1', 'Item G2', 'Item G3'],
                    August: ['Item H1', 'Item H2', 'Item H3'],
                    September: ['Item I1', 'Item I2', 'Item I3'],
                },
                Q4: {
                    October: ['Item J1', 'Item J2', 'Item J3'],
                    November: ['Item K1', 'Item K2', 'Item K3'],
                    December: ['Item L1', 'Item L2', 'Item L3'],
                },
            },
        },
    ];

    return (
        <div className='my-32 max-w-7xl mx-auto'>
            <h1 className='text-xl font-semibold mb-4'>Dynamic Column Collapse Table</h1>
            <table className='min-w-full border border-gray-300'>
                <thead>
                    <tr className='bg-gray-200'>
                        <th className='px-4 py-2 border border-gray-300'>Year</th>
                        <th className='px-4 py-2 border border-gray-300'>Quarter</th>
                        {/* Dynamically added columns */}
                        {Object.values(expandedView).some((view) => view.quarter) && (
                            <th className='px-4 py-2 border border-gray-300'>Month</th>
                        )}
                        {Object.values(expandedView).some((view) => view.month) && (
                            <th className='px-4 py-2 border border-gray-300'>Items</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <React.Fragment key={item.year}>
                            <tr>
                                {/* Year Column */}
                                <td className='px-4 py-2 border border-gray-300'>{item.year}</td>

                                {/* Quarter Column */}
                                <td className='px-4 py-2 border border-gray-300'>
                                    {Object.keys(item.quarters).map((quarter) => (
                                        <div key={quarter}>
                                            <button
                                                onClick={() => toggleQuarter(item.year, quarter)}
                                                className='text-blue-500 underline'
                                            >
                                                {quarter} {expandedView[item.year].quarter === quarter ? '▲' : '▼'}
                                            </button>
                                        </div>
                                    ))}
                                </td>

                                {/* Conditional Month Column */}
                                {expandedView[item.year].quarter && (
                                    <td className='px-4 py-2 border border-gray-300'>
                                        {Object.keys(item.quarters[expandedView[item.year].quarter]).map((month) => (
                                            <div key={month}>
                                                <button
                                                    onClick={() => toggleMonth(item.year, month)}
                                                    className='text-blue-500 underline'
                                                >
                                                    {month} {expandedView[item.year].month === month ? '▲' : '▼'}
                                                </button>
                                            </div>
                                        ))}
                                    </td>
                                )}

                                {/* Conditional Items Column */}
                                {expandedView[item.year].quarter &&
                                    expandedView[item.year].month && (
                                        <td className='px-4 py-2 border border-gray-300'>
                                            {item.quarters[expandedView[item.year].quarter][expandedView[item.year].month].map(
                                                (item, idx) => (
                                                    <div key={idx}>{item}</div>
                                                )
                                            )}
                                        </td>
                                    )}
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Demo;
