import React from 'react';
import TaxPayerForm from './Form';

const TaxPayer = () => {
    return (
        <>
            <div className='mb-8'>
                <h1 className='text-5xl font-light mb-2'>Generate Certificate</h1>
                <p className='text-md text-gray-600'>Input these details and get the invoice certificate</p>
            </div>
            <TaxPayerForm></TaxPayerForm>
        </>
    );
};

export default TaxPayer;